import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// custom components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CourseSections from "sections/CourseSections.jsx";
import Loading from "sections/Loading.jsx";
// external modules
import { Helmet } from "react-helmet";
import CircularProgressbar from 'react-circular-progressbar';
import { graphql, navigate } from 'gatsby';
import Firebase from "../firebase";
// Auth
import { isAuthenticated, getUserEmail, setCallbackLink } from 'Auth/AuthService';
// styles
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.jsx";
import 'react-circular-progressbar/dist/styles.css';

const fillOrange = {
  path: {
    stroke: `rgb(234, 144, 9)`
  },
  text: {
    fill: '#000', fontSize: '36px', fontWeight: "bold"
  }
}

const fillGreen = {
  path: {
    stroke: `rgb(76, 175, 80)`
  },
  text: {
    fill: '#000', fontSize: '36px', fontWeight: "bold"
  }
}

class CourseTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progressArray: []
    }
  }

  componentDidMount() {
    if (!isAuthenticated()) {
      const { pathname } = this.props.location;
      setCallbackLink(pathname);
      navigate("/login")
    }
    else {
      const { pageContext } = this.props;
      const email = getUserEmail();
      pageContext.sections.map(section => {
        var total = 0;
        var completed = 0;
        Firebase
          .collection("lessons")
          .where("courseName", "==", pageContext.title)
          .where("section", "==", section)
          .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              const { title } = doc.data();
              total += 1;
              Firebase
                .collection("completed-lessons")
                .where("courseName", "==", pageContext.title)
                .where("title", "==", title)
                .where("user", "==", email)
                .limit(1)
                .get()
                .then(subSnapshot => {
                  subSnapshot.forEach(subDoc => {
                    completed += 1;
                  })
                })
              })
            })
        setTimeout(() => {
          this.getProgress(completed, total);
        }, 2000);
      })
      Firebase
        .collection("users")
        .doc(getUserEmail())
        .get()
        .then(doc => {
          var { recent } = doc.data();
          if (recent === undefined || recent === null) {
            Firebase
              .collection("users")
              .doc(getUserEmail())
              .update({ recent: [pageContext.title] })
          }
          else if (recent.includes(pageContext.title)) {
            var index = recent.indexOf(pageContext.title)
            var temp = recent[index];
            recent.splice(index, 1);
            recent.unshift(temp);
            Firebase
              .collection("users")
              .doc(getUserEmail())
              .update({ recent })
          }
          else if (recent.length === 7) {
              recent.splice(-1,1).push(pageContext.title);
              Firebase
                .collection("users")
                .doc(getUserEmail())
                .update({ recent })
          }
          else {
            recent.push(pageContext.title);
            Firebase
              .collection("users")
              .doc(getUserEmail())
              .update({ recent })
          }
        })
    }
  }

  getProgress(completed, total) {
    var temp = this.state.progressArray;
    if (total === 0 || completed === 0) {
      temp.push(0);
    }
    else {
      temp.push(completed / total * 100);
    }
    this.setState({ progressArray: temp });
  }

  render() {
    const { classes, pageContext, data } = this.props;
    const { fluid } = data.allContentfulCourses.edges[0].node.cover;
    const { progressArray } = this.state;

    return (
      <div>
        {isAuthenticated() ?
          <div>
            <Helmet>
              <title>{`${pageContext.title} | Able University`}</title>
            </Helmet>
            <Header
              brand="Able University"
              color="transparent"
              headerLink="/"
              links={<HeaderLinks dropdownHoverColor="primary" />}
              fixed
              changeColorOnScroll={{
                height: 300,
                color: "primary"
              }}
            />
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${fluid.src}")` }}
            >
              <div className={classes.container}>
                <GridContainer>
                  <GridItem
                    md={8}
                    sm={8}
                    className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}
                  >
                    <h1 className={classes.title}>{pageContext.title}</h1>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            <div className={classes.spacer} />
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem md={8}>
                  <div dangerouslySetInnerHTML={{ __html: pageContext.description}} />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem md={8}>
                  <h3 style={{ fontWeight: "bold" }}>What You'll Learn:</h3>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem md={8}>
                  <GridContainer>
                    {pageContext.sections.map((section, index) => {
                      return (
                        <GridItem md={6} key={index}>
                          <h5>
                            <span style={{ marginRight: 10 }}>
                              <i className="fas fa-check" />
                            </span>
                            {section}
                          </h5>
                        </GridItem>
                      )
                    })}
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <Card style={{ textAlign: "center" }}>
                    <CardBody>
                      {progressArray.length === 0 ?
                        <GridContainer>
                          {pageContext.sections.map((section, index) => {
                            return (
                              <GridItem sm={1} key={index}>
                                <CircularProgressbar
                                  percentage={0}
                                  text={index + 1}
                                  styles={fillOrange}
                                />
                              </GridItem>
                            )
                          })}
                        </GridContainer>
                      :
                      <GridContainer>
                        {progressArray.map((progress, index) => {
                          return (
                            <GridItem sm={1} key={index}>
                              <CircularProgressbar
                                percentage={progress}
                                text={index + 1}
                                styles={
                                  progress === 100
                                    ? fillGreen
                                    : fillOrange
                                }
                              />
                            </GridItem>
                          )
                        })}
                      </GridContainer>
                      }
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
              <div className={classes.spacer} />
              {pageContext.sections.map((section, index) => {
                return (
                  <CourseSections
                    key={index}
                    index={index + 1}
                    course={pageContext.title}
                    section={pageContext.sections[index]}
                    description={pageContext.sectionInfo[index]}
                  />
                )
              })}
              <Footer
                content={
                  <div>
                    <div className={classes.left}>
                      <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                          <a
                            href="https://portal.ablehomecarellc.com/dashboard/"
                            className={classes.block}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Portal
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                          <a
                            href="https://outlook.office365.com"
                            className={classes.block}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Webmail
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                          <a
                            href="https://policies.ablehomecarellc.com"
                            className={classes.block}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Policies
                          </a>
                        </ListItem>
                      </List>
                    </div>
                    <div className={classes.right}>
                      &copy; {1900 + new Date().getYear()} Able Home Care LLC
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        :
        <Loading />
        }
      </div>
    )
  }
};

export const query = graphql`
  query CoverPhotos($slug: String!) {
    allContentfulCourses(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          cover {
            fluid(maxWidth: 1000, maxHeight: 563){
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

export default withStyles(headersStyle)(CourseTemplate);
