import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// custom components
import Button from "components/CustomButtons/Button.jsx";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
// external modules
import { Helmet } from "react-helmet";
import CircularProgressbar from 'react-circular-progressbar';
import { navigate } from "gatsby";
import Firebase from "../firebase";
// Auth
import { isAuthenticated, getUserEmail } from 'Auth/AuthService';
// styles
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.jsx";
import 'react-circular-progressbar/dist/styles.css';

import image from "assets/img/backgrounds/bg7.jpg";

const fillOrange = {
  path: {
    stroke: `rgb(234, 144, 9)`
  },
  text: {
    fill: '#000', fontSize: '36px', fontWeight: "bold"
  }
}

const fillGreen = {
  path: {
    stroke: `rgb(76, 175, 80)`
  },
  text: {
    fill: '#000', fontSize: '36px', fontWeight: "bold"
  }
}

class CourseSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewDetails: false,
      lessonList: [],
      lessons: 0,
      videos: 0,
      quizzes: 0,
      progress: 0
    };
  }

  componentDidMount() {
    const { course, section } = this.props;
    const email = getUserEmail();
    var lessonList = [];
    var videos = 0;
    var lessons = 0;
    var quizzes = 0;

    Firebase
      .collection("lessons")
      .where("courseName", "==", course)
      .where("section", "==", section)
      .orderBy("order")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const { title, slug, type } = doc.data();
          if (type === "lesson") {
            lessons += 1;
          } else if (type === "video") {
            videos += 1
          } else {
            quizzes += 1;
          }
          Firebase
            .collection("completed-lessons")
            .where("courseName", "==", course)
            .where("title", "==", title)
            .where("user", "==", email)
            .limit(1)
            .get()
            .then(snapshot => {
              var count = 0;
              snapshot.forEach(lesson => {
                count += 1;
              })
              if (count > 0) {
                lessonList.push({
                  title,
                  slug,
                  type,
                  completed: true
                })
              }
              else {
                lessonList.push({
                  title,
                  slug,
                  type,
                  completed: false
                })
              }
            })
        })
        this.setState({
          lessonList,
          lessons,
          videos,
          quizzes
        })
      })
    setTimeout(() => {
      this.setState({ progress: this.getProgress() });
    }, 2000);
  }

  getProgress() {
    const { lessonList } = this.state;
    var completed = 0;
    var length = 0;

    lessonList.forEach(lesson => {
      if (lesson.completed) {
        completed += 1;
        length += 1;
      }
      else {
        length += 1;
      }
    })
    if (completed === 0 || length === 0) {
      return 0;
    }
    else {
      return (completed / length * 100);
    }
  }

  getIcon(type, completed) {
    if (completed) {
      return (
        <ListItemIcon>
          <span style={{ marginRight: 10, fontSize: 24, color: "green" }}><i className="far fa-check-circle" /></span>
        </ListItemIcon>
      )
    }
    if (type === "lesson") {
      return (
        <ListItemIcon>
          <span style={{ marginRight: 10, fontSize: 24, color: "blue" }}><i className="fas fa-book" /></span>
        </ListItemIcon>
      )
    }
    if (type === "video") {
      return (
        <ListItemIcon>
          <span style={{ marginRight: 10, fontSize: 24, color: "purple" }}><i className="far fa-play-circle" /></span>
        </ListItemIcon>
      )
    }
    else {
      return (
        <ListItemIcon>
          <span style={{ marginRight: 10, fontSize: 24, color: "orange" }}><i className="fas fa-edit" /></span>
        </ListItemIcon>
      )
    }
  }

  render() {
    const { classes, section, description, index } = this.props;
    const { lessonList, lessons, videos, quizzes, progress } = this.state;
    const startSlug = lessonList.length > 0 ? lessonList[0].slug || "" : "";

    return (
        <GridContainer justify="center" style={{ marginBottom: 20 }}>
          <GridItem sm={1}>
            <CircularProgressbar
              percentage={progress}
              text={index}
              styles={
                progress === 100
                  ? fillGreen
                  : fillOrange
              }
            />
          </GridItem>
          <GridItem xs={9}>
            <Card>
              <CardHeader color="primary">{section}</CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem sm={3}>
                    <h5 style={{ fontWeight: 500 }}>
                      <span style={{ marginRight: 10, fontSize: 24 }}>
                        <i className="far fa-play-circle" />
                      </span>
                      <span>{`${videos} Videos`}</span>
                    </h5>
                  </GridItem>
                  <GridItem sm={3}>
                    <h5 style={{ fontWeight: 500 }}>
                      <span style={{ marginRight: 10, fontSize: 24 }}>
                        <i className="fas fa-book" />
                      </span>
                      <span>{`${lessons} Lessons`}</span>
                    </h5>
                  </GridItem>
                  <GridItem sm={3}>
                    <h5 style={{ fontWeight: 500 }}>
                      <span style={{ marginRight: 10, fontSize: 24 }}>
                        <i className="fas fa-edit" />
                      </span>
                      <span>{`${quizzes} Quizzes`}</span>
                    </h5>
                  </GridItem>
                </GridContainer>
                <h5 style={{color: "#000"}}>
                  {description}
                </h5>
              </CardBody>
              {this.state.viewDetails && (
                <CardBody>
                  <List>
                    {lessonList.map(lesson => {
                      return (
                        <ListItem
                          button
                          style={{ marginTop: 5, marginBottom: 5 }}
                          onClick={() => navigate(lesson.slug)}
                          key={lesson.title}
                        >
                          {this.getIcon(lesson.type, lesson.completed)}
                          <ListItemText>
                            {lesson.title}
                          </ListItemText>
                        </ListItem>
                      )
                    })}
                  </List>
                </CardBody>
              )}
              <CardFooter>
                <Button block style={{ marginRight: 10, marginLeft: 10 }} onClick={() => this.setState({ viewDetails: !this.state.viewDetails })}>
                  {this.state.viewDetails ? "Hide Details" : "View Details"}
                </Button>
                <Button
                  block
                  color="primary"
                  style={{ marginRight: 10, marginLeft: 10 }}
                  onClick={() => startSlug !== "" ? navigate(startSlug) : null}
                >
                  Start
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
    )
  }
};

export default withStyles(headersStyle)(CourseSection);
